body{
  width: 100% !important;
}
.container{
  width: 100% !important;
}

.content{
  width: auto;
  margin: 3em;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.img-fluid{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.data{
  position: relative;
  word-wrap: break-word;
  display: inline-block;
  margin-top: 0.5em;
  width: 100%;
  margin-bottom: 0.51em;
}

.img-frame{
  width: 40%;
  height: 130px;
  border: 2px solid burlywood;
  padding: 1em;
  flex-direction: column;
}

.text-bold {
  font-weight: bold;
  text-transform: capitalize;
}
.info-frame{
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-left: 1em;
  padding-right: 1em;
}
.box{
  border: 1px solid #200599;
  margin-top: 2em;
  padding: 1em;
  box-shadow: 5px 5px 5px gray;
  margin-bottom: 2em;
  flex-direction: row;
  display: flex;
  width: 40%;
  min-height: 10em;
}
@media screen and (max-width: 800px) {
  .box{
    width: 100%;
  }

}
@media screen and (min-width: 500px) and (max-width: 800px){
  .info-frame{
    width: 50%;
  }
  .img-frame{
    width: 50%;
  }
  .img-frame{
    height: 200px;
  }
}